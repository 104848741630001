/*--------------------------------------------------------------
10. Isotope
----------------------------------------------------------------*/
.cs_isotop {
  transition: all 0.4s ease;
}

.cs_isotop.cs_has_gutter_24 {
  margin-left: -12px;
  margin-right: -12px;
  margin-bottom: -24px;

  .cs_isotop_item {
    padding: 0 12px;
    margin-bottom: 24px;
  }
}
.cs_isotop.cs_has_gutter_24_67 {
  margin-left: -12px;
  margin-right: -12px;
  margin-bottom: -67px;
  @media (max-width: 991px) {
    margin-bottom: -30px;
  }

  .cs_isotop_item {
    padding: 0 12px;
    margin-bottom: 67px;
    @media (max-width: 991px) {
      margin-bottom: 30px;
    }
  }
}
.cs_isotop.cs_has_gutter_80 {
  margin-left: -40px;
  margin-right: -40px;
  margin-bottom: -80px;

  .cs_isotop_item {
    padding: 0 40px;
    margin-bottom: 80px;
  }
}
.cs_isotop.cs_has_gutter_40 {
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -30px;

  .cs_isotop_item {
    padding: 0 20px;
    margin-bottom: 30px;
  }
}

.cs_isotop.cs_has_gutter_100 {
  margin-left: -50px;
  margin-right: -50px;
  margin-bottom: -40px;

  .cs_isotop_item {
    padding: 0 50px;
    margin-bottom: 40px;
  }
}

.cs_isotop_col_6 .cs_grid_sizer,
.cs_isotop_col_6 .cs_isotop_item {
  width: 16.666667%;
}

.cs_isotop_col_5 .cs_grid_sizer,
.cs_isotop_col_5 .cs_isotop_item {
  width: 20%;
}

.cs_isotop_col_4 .cs_grid_sizer,
.cs_isotop_col_4 .cs_isotop_item {
  width: 25%;
}

.cs_isotop_col_3 .cs_grid_sizer,
.cs_isotop_col_3 .cs_isotop_item {
  width: 33.333333%;
}

.cs_isotop_col_2 .cs_grid_sizer,
.cs_isotop_col_2 .cs_isotop_item {
  width: 50%;
}

.cs_isotop_col_1 .cs_grid_sizer,
.cs_isotop_col_1 .cs_isotop_item {
  width: 100%;
}

.cs_grid_sizer {
  width: 33.333333%;
}

.cs_isotop_col_5 .cs_w20,
.cs_isotop_col_4 .cs_w20,
.cs_isotop_col_3 .cs_w20,
.cs_isotop_col_2 .cs_w20,
.cs_isotop_col_1 .cs_w20 {
  width: 20%;
}

.cs_isotop_col_5 .cs_w25,
.cs_isotop_col_4 .cs_w25,
.cs_isotop_col_3 .cs_w25,
.cs_isotop_col_2 .cs_w25,
.cs_isotop_col_1 .cs_w25 {
  width: 25%;
}

.cs_isotop_col_5 .cs_w33,
.cs_isotop_col_4 .cs_w33,
.cs_isotop_col_3 .cs_w33,
.cs_isotop_col_2 .cs_w33,
.cs_isotop_col_1 .cs_w33 {
  width: 33.333333%;
}

.cs_isotop_col_5 .cs_w50,
.cs_isotop_col_4 .cs_w50,
.cs_isotop_col_3 .cs_w50,
.cs_isotop_col_2 .cs_w50,
.cs_isotop_col_1 .cs_w50 {
  width: 50%;
}

.cs_isotop_col_5 .cs_w66,
.cs_isotop_col_4 .cs_w66,
.cs_isotop_col_3 .cs_w66,
.cs_isotop_col_2 .cs_w66,
.cs_isotop_col_1 .cs_w66 {
  width: 66.666666%;
}

.cs_isotop_col_5 .cs_w100,
.cs_isotop_col_4 .cs_w100,
.cs_isotop_col_3 .cs_w100,
.cs_isotop_col_2 .cs_w100,
.cs_isotop_col_1 .cs_w100 {
  width: 100%;
}

.cs_isotop_filter.cs_style_1 {
  li {
    margin-top: 10px;
    &:not(:last-child) {
      margin-right: 28px;
    }
  }
  a {
    display: inline-block;
    position: relative;
    padding-bottom: 10px;
    &::before {
      content: '';
      height: 9px;
      width: 9px;
      position: absolute;
      left: 50%;
      margin-left: -5px;
      border-radius: 50%;
      background-color: $accent;
      bottom: -5px;
      transition: all 0.4s ease;
      transform: scale(0);
    }
  }

  ul {
    border-bottom: 1px solid $ternary;
    flex-wrap: wrap;
    display: inline-flex;
    justify-content: flex-start;
  }

  .active {
    a {
      color: $accent;
      &::before {
        transform: scale(1);
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .cs_isotop.cs_has_gutter_100 .cs_isotop_item {
    padding: 0 15px;
    margin-bottom: 30px;
  }

  .cs_isotop.cs_has_gutter_100 {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -30px;
  }
  .cs_isotop.cs_has_gutter_80 {
    margin-left: -12px;
    margin-right: -12px;
    margin-bottom: -25px;

    .cs_isotop_item {
      padding: 0 12px;
      margin-bottom: 25px;
    }
  }
  .cs_isotop_col_4 .cs_grid_sizer,
  .cs_isotop_col_4 .cs_isotop_item {
    width: 33.333333%;
  }
}

@media screen and (max-width: 991px) {
  .cs_isotop_col_4 .cs_grid_sizer,
  .cs_isotop_col_4 .cs_isotop_item {
    width: 50%;
  }

  .cs_isotop_col_4 .cs_w50 {
    width: 100%;
  }

  .cs_isotop_col_3 .cs_grid_sizer,
  .cs_isotop_col_3 .cs_isotop_item {
    width: 50%;
  }

  .cs_isotop_col_2 .cs_grid_sizer,
  .cs_isotop_col_2 .cs_isotop_item {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .cs_isotop_col_5 .cs_w50,
  .cs_isotop_col_4 .cs_w50,
  .cs_isotop_col_3 .cs_w50,
  .cs_isotop_col_2 .cs_w50,
  .cs_isotop_col_1 .cs_w50,
  .cs_isotop_col_5 .cs_w66,
  .cs_isotop_col_4 .cs_w66,
  .cs_isotop_col_3 .cs_w66,
  .cs_isotop_col_2 .cs_w66,
  .cs_isotop_col_1 .cs_w66 {
    width: 100%;
  }

  .cs_isotop_col_5 .cs_grid_sizer,
  .cs_isotop_col_5 .cs_isotop_item {
    width: 100%;
  }

  .cs_isotop_col_4 .cs_grid_sizer,
  .cs_isotop_col_4 .cs_isotop_item {
    width: 100%;
  }

  .cs_isotop_col_3 .cs_grid_sizer,
  .cs_isotop_col_3 .cs_isotop_item {
    width: 100%;
  }

  .cs_isotop_col_2 .cs_grid_sizer,
  .cs_isotop_col_2 .cs_isotop_item {
    width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .cs_isotop_filter.cs_style_1 ul {
    border: none;
  }

  .cs_isotop_filter.cs_style_1 {
    li {
      margin-bottom: 8px;
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
    a {
      padding-bottom: 3px;
    }
  }
}
