/*--------------------------------------------------------------
  22. Numberbox
----------------------------------------------------------------*/
.cs_number_box.cs_style_1 {
  .cs_number_box_number {
    height: 57px;
    width: 57px;
    border-radius: 50%;
    color: #fff;
    margin-bottom: 20px;
  }
  .cs_number_box_title {
    margin-bottom: 20px;
  }
}
