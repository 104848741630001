/*--------------------------------------------------------------
  3. Spacing
----------------------------------------------------------------*/
@media screen and (min-width: 992px) {
  @for $i from 1 through 200 {
    .cs_height_#{$i} {
      height: $i + px;
    }
  }
}

@media screen and (max-width: 991px) {
  @for $i from 1 through 200 {
    .cs_height_lg_#{$i} {
      height: $i + px;
    }
  }
}
