/*--------------------------------------------------------------
  11. Hero
----------------------------------------------------------------*/
.cs_hero_video_icon {
  svg {
    animation: heroVideoIconAnimo 3s linear infinite;
  }
  &:hover {
    transform: scale(1.7);
    svg {
      animation: initial;
    }
  }
}
.cs_down_btn {
  height: 70px;
  width: 40px;
  border: 1px solid currentColor;
  border-radius: 20px;
  margin-top: 44px;
}
.cs_hero.cs_style_1 {
  padding-top: 241px;
  @media (max-width: 991px) {
    padding-top: 170px;
  }
  .cs_hero_subtitle {
    margin-bottom: 24px;
  }
  .cs_hero_title {
    margin-bottom: 82px;
    @media (max-width: 991px) {
      margin-bottom: 50px;
    }
  }

  .cs_video_block_wrap {
    &::before {
      content: '';
      position: absolute;
      height: 50%;
      width: 100%;
      left: 0;
      bottom: 0;
      background-color: #fff;
    }
  }
  .container {
    position: relative;
  }
  .cs_hero_shape {
    left: -100px;
    top: -9px;
    animation: heroShapeAnim 20s linear infinite alternate;
  }
  .cs_hero_text {
    z-index: 2;
  }
}
@keyframes heroShapeAnim {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes heroVideoIconAnimo {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

.cs_hero.cs_style_2 {
  padding: 186px 0 100px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    height: 440px;
    width: 440px;
    border-radius: 50%;
    border: 1px solid rgba(163, 163, 163, 0.18);
    top: 36%;
    right: -200px;
  }
  > .container {
    position: relative;
    z-index: 2;
  }
  .cs_hero_img {
    flex: none;
    height: 410px;
    max-width: 740px;
    overflow: hidden;
    border-radius: 500px;
    @media (max-width: 1199px) {
      height: 300px;
    }
    @media (max-width: 575px) {
      height: 300px;
    }
  }
  .cs_hero_text {
    margin-bottom: 66px;
  }
  .cs_hero_top_title {
    display: flex;
    align-items: center;
    gap: 22px;
  }
  .cs_hero_img_in {
    margin-top: 60px;
    @media (max-width: 575px) {
      height: 100%;
      transform: initial !important;
      margin-top: 0;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .cs_hero_subtitle {
    padding-left: 90px;
    @media (max-width: 1400px) {
      padding-left: 60px;
    }
    @media (max-width: 991px) {
      padding-left: 0px;
    }
  }
  .cs_hero_arrow {
    position: relative;
    height: 10px;
    width: 148px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    &::after {
      content: '';
      position: absolute;
      height: 1px;
      background-color: currentColor;
      width: 100%;
      top: 46%;
      left: 0;
    }
    i {
      margin-right: -2px;
      font-size: 10px;
    }
  }
  .cs_hero_mike {
    animation: wave-animation 9.6s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
  .cs_down_btn {
    animation: jumping 2s linear infinite;
    &:hover {
      animation-play-state: paused;
    }
  }
}
@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(14deg);
  }

  20% {
    transform: rotate(-8deg);
  }

  30% {
    transform: rotate(14deg);
  }

  40% {
    transform: rotate(-4deg);
  }

  50% {
    transform: rotate(10deg);
  }

  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes jumping {
  0%,
  100% {
    transform: translateY(0px);
  }
  90% {
    transform: translateY(20px);
  }
}

.cs_hero.cs_style_3 {
  padding: 160px 0 100px;
  height: 100vh;
  max-height: 1200px;
  min-height: 650px;
  background-attachment: fixed;
  .cs_hero_title {
    margin-bottom: 70px;
  }
  .cs_moving_text_wrap {
    padding: 31px 0;
    width: 425px;
    border: 1px solid #a3a3a3;
    border-left: 0;
    border-right: 0;
    @media (max-width: 500px) {
      width: 100%;
    }
  }
}

.cs_hero.cs_style_4 {
  padding: 240px 0 160px;
  background-attachment: fixed;
  @media (max-width: 991px) {
    padding: 170px 0 100px;
  }
  .cs_video_block.cs_style_1 {
    height: 376px;
  }
  .cs_hero_title {
    margin-bottom: 67px;
    @media (max-width: 991px) {
      margin-bottom: 35px;
    }
  }
  @media (min-width: 1400px) {
    .cs_hero_subtitle {
      padding-right: 90px;
    }
  }
  @media (max-width: 991px) {
    .row {
      flex-direction: column-reverse;
    }
    .cs_video_block.cs_style_1 {
      margin-bottom: 25px;
    }
  }
  .container {
    position: relative;
    z-index: 2;
  }
  .cs_hero_shape {
    position: absolute;
    left: 4%;
    top: 13%;
    rotate: -9deg;
    svg {
      animation: semiRotate 9.6s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
  }
  .cs_down_btn {
    animation: jumping 2s linear infinite;
  }
}
@keyframes semiRotate {
  50% {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateY(15px) rotate(-20deg);
  }
}
.cs_hero.cs_style_5 {
  height: 100vh;
  max-height: 1000px;
  padding: 170px 0 100px;
  overflow: hidden;
  background-color: $primary;
  .cs_hero_subtitle {
    color: #a3a3a3;
    margin-bottom: 40px;
  }
  .cs_hero_title {
    margin-bottom: 25px;
  }
  .cs_hero_top_title {
    display: inline-flex;
    color: #a5d190;
    font-weight: 500;
    gap: 5px;
    border: 1px solid #fff;
    padding: 5px 18px;
    border-radius: 15px;
    align-items: center;
    margin-bottom: 24px;
    @media (max-width: 330px) {
      padding: 5px 12px;
    }
    img {
      animation: spinAnimaiton 6s linear infinite;
    }
  }
  .cs_hero_btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px 30px;
    flex-wrap: wrap;
  }
  .container {
    position: relative;
    z-index: 2;
  }
  @media (max-width: 991px) {
    br {
      display: none;
    }
  }
}

@keyframes spinAnimaiton {
  0% {
    transform: rotateY(0deg);
  }
  75% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

#background-wrap {
  bottom: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
}
.bubble {
  border-radius: 50%;
  height: 200px;
  position: absolute;
  width: 200px;
}
/* ANIMATIONS */
.cs_hero_shape_1 {
  animation: animateBubble 25s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  left: -5%;
  top: 5%;
  transform: scale(0.6);
}

.cs_hero_shape_2 {
  animation: animateBubble 20s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  left: 5%;
  top: 80%;
  transform: scale(0.4);
}

.cs_hero_shape_3 {
  animation: animateBubble 28s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  left: 10%;
  top: 40%;
  transform: scale(0.7);
}

.cs_hero_shape_4 {
  animation: animateBubble 22s linear infinite,
    sideWays 3s ease-in-out infinite alternate;
  left: 20%;
  top: 0;
  transform: scale(0.3);
}

.cs_hero_shape_5 {
  animation: animateBubble 29s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  left: 30%;
  top: 50%;
  transform: scale(0.5);
}

.cs_hero_shape_6 {
  animation: animateBubble 21s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  left: 50%;
  top: 0;
  transform: scale(0.8);
}

.cs_hero_shape_7 {
  animation: animateBubble 20s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  left: 65%;
  top: 70%;
  transform: scale(0.4);
}

.cs_hero_shape_8 {
  animation: animateBubble 22s linear infinite,
    sideWays 3s ease-in-out infinite alternate;
  left: 80%;
  top: 10%;
  transform: scale(0.3);
}

.cs_hero_shape_9 {
  animation: animateBubble 29s linear infinite,
    sideWays 4s ease-in-out infinite alternate;
  left: 90%;
  top: 50%;
  transform: scale(0.6);
}

.cs_hero_shape_10 {
  animation: animateBubble 26s linear infinite,
    sideWays 2s ease-in-out infinite alternate;
  left: 80%;
  top: 80%;
  transform: scale(0.3);
}

/* OBJECTS */

@keyframes animateBubble {
  0% {
    margin-top: 1000px;
  }
  100% {
    margin-top: -100%;
  }
}

@keyframes sideWays {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: 50px;
  }
}
.cs_hero.cs_style_6 {
  height: 100vh;
  min-height: 600px;
  max-height: 900px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 180px 0 100px;
  background-color: #000;
  .cs_hero_title {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
    span {
      color: transparent;
      display: inline-block;
      -webkit-text-stroke: 2px #fff;
    }
    svg {
      position: absolute;
      top: -60px;
      right: 10px;
      @media (max-width: 991px) {
        display: none;
      }
    }
  }
  .cs_down_btn {
    position: relative;
    height: 35px;
    width: 20px;
    border-radius: 10px;
    border: 2px solid #fff;
    margin-left: -10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
    &:before {
      content: '';
      height: 5px;
      width: 5px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      animation: UpAndDown 3s infinite;
    }
  }
  .cd-headline.slide b {
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 10px;
    padding-bottom: 10px;
    &::before,
    &::after {
      content: '';
      position: absolute;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: currentColor;
      top: 50%;
      margin-top: -4px;
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }
  .cd-headline.slide .cd-words-wrapper {
    width: initial !important;
  }
}
@keyframes UpAndDown {
  0%,
  100% {
    top: 30%;
  }
  50% {
    top: 70%;
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(0.8);
  }
}
