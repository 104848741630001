/*--------------------------------------------------------------
  16. Portfolio
----------------------------------------------------------------*/
.cursor {
  position: fixed;
  left: 0;
  pointer-events: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: $secondary;
  background-color: #fff;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  z-index: 999;
  opacity: 0;
  mix-blend-mode: hard-light;
  transition: 0.15s;
}
.cs_portfolio.cs_style_1 {
  .cs_portfolio_thumb {
    display: block;
    margin-bottom: 27px;
    // cursor: url(../img/drag.png), auto;
    @media (max-width: 991px) {
      margin-bottom: 10px;
    }
    img {
      width: 100%;
      border-radius: inherit;
    }
  }
  .cs_portfolio_title {
    margin-bottom: 8px;
    @media (max-width: 991px) {
      margin-bottom: 5px;
    }
  }
  .cs_portfolio_btn {
    display: inline-flex;
    padding-left: 25px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      height: 1px;
      width: 15px;
      background-color: currentColor;
      left: 0;
      top: 50%;
      margin-top: 0px;
      transition: all 0.4s;
    }
    &:hover {
      &::before {
        width: 4px;
        height: 4px;
        left: 12px;
        margin-top: -2px;
        border-radius: 50%;
      }
    }
  }
}
.cs_portfolio.cs_style_2 {
  min-height: 620px;
  display: flex;
  align-items: center;
  position: relative;
  @media (max-width: 991px) {
    min-height: 500px;
    padding: 80px 0;
  }
  .cs_portfolio_thumb {
    position: absolute;
    height: 100%;
    width: 80%;
    right: 0;
    top: 0;
    &::before {
      content: '';
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.9) 0%,
        rgba(255, 255, 255, 0) 49.95%
      );
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }
  .cs_portfolio_minititle {
    margin-bottom: 15px;
    @media (max-width: 991px) {
      margin-bottom: 10px;
    }
  }
  .cs_portfolio_title {
    margin-bottom: 16px;
    transition: all 0.4s ease;
    &:hover {
      color: $accent;
    }
    a {
      background-image: linear-gradient(currentColor, currentColor);
      background-repeat: no-repeat;
      background-position: bottom left;
      background-size: 0% 3px;
      transition: background-size 1200ms ease;
      color: inherit;
      &:hover {
        background-size: 100% 3px;
      }
    }
  }
  .cs_portfolio_subtitle {
    margin-bottom: 35px;
    @media (max-width: 991px) {
      margin-bottom: 20px;
    }
  }
  .cs_portfolio_text {
    position: relative;
    z-index: 2;
    padding-right: 30px;
    @media (max-width: 767px) {
      br {
        display: none;
      }
    }
  }
}
